<template>
	<div class="user-information">
		<jw-form ref="form" v-bind="form" />
	</div>
</template>
<script lang="jsx">
import Server from './apis.js'
export default {
  data () {
    return {
      form: {
        read: true,
        bind: {
          props: {
            labelWidth: '192px'
          }
        },
        data: [
          {
            label: '姓名',
            prop: 'name',
            render: (h, store, value) => {
              return <span>{value.name}</span>
            }
          },
          {
            label: '工号',
            prop: 'jobNo',
            render: (h, store, value) => {
              return <span>{value.jobNo}</span>
            }
          },
          {
            label: '性别',
            tag: 'el-radio-group',
            prop: 'gender',
            render: (h, store, value) => {
              return Number(value.gender) === 1 ? '男' : Number(value.gender) === 0 ? '女' : '-'
            }
          },
          {
            tag: 'jw-mobile',
            label: '手机号码',
            prop: 'phone',
            bind: {
              attrs: {
                placeholder: '请输入手机号码'
              }
            }
          },
          {
            tag: 'el-input',
            label: '邮箱',
            prop: 'mailbox'
          }
        ],
        value: {
          name: '',
          jobNo: '',
          gender: '',
          phone: '',
          mailbox: ''
        }
      }
    }
  },
  mounted () {
    this.$apis = new Server(this.$axios, this.$message.warning)
    this.$apis.getUserMessage(res => {
      this.$refs.form.$setValue({
        name: res.data.employeeName,
        jobNo: res.data.employeeNo,
        gender: res.data.employeeSex,
        phone: res.data.phone,
        mailbox: res.data.email || '-'
      })
    })
  }
}
</script>
<style lang="less" scoped>
  .user-information {
    min-height: 500px;
    padding: 40px 20px 0;
  }
</style>
